/* eslint-disable no-use-before-define */
import {
  Model, createActionCreaters, Effects, Reducers,
} from '@shuwen/dva-ts-wrapper';
import * as loginService from '@/services/login';
import {
  GlobalState,
} from './types';

export type IExampleModelState = Readonly<{
  list: number[];
}>

interface IReducersPayloads {
  save: void | Partial<IExampleModelState>;
}

type Callback = { options: any; onResult: (error: any, data?: any) => void };

interface IEffectsPayloads {
  fetch: void;
  demo: Callback;
}

const reducers: Reducers<IExampleModelState, IReducersPayloads> = {
  save(state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },
};

const effects: Effects<GlobalState, IEffectsPayloads> = {
  *fetch({ payload }, { call, put }) {
    yield put(meetingActions.reducers.save({}, true));
  },
  // demo
  *demo({ payload }, { call }) {
    const { options, onResult } = payload;
    const result = yield call(loginService.detection, options);
    if (result && result.success) {
      onResult && onResult(null, result.data);
    } else {
      onResult && onResult(result, (result && (result.msg || result.message)) || '系统异常，请刷新再试');
    }
  },
};


const meetingModel: Model<IExampleModelState, GlobalState, IReducersPayloads, IEffectsPayloads> = {
  namespace: 'meeting',
  state: {
    list: [],
  },

  subscriptions: {
    setup({ dispatch, history }) {  // eslint-disable-line

    },
  },

  effects,
  reducers,
};

export default meetingModel;

export const meetingActions = createActionCreaters(meetingModel);
