import '@babel/polyfill';
import 'url-polyfill';
import dva, { DvaInstance } from 'dva';
import { createBrowserHistory } from 'history';
import { message } from 'antd';
import createLoading from 'dva-loading';
import * as ModelSet from './models/index';
import 'antd/dist/antd.min.css';
import './index.less';

// eslint-disable-next-line no-unused-vars
// const ERROR_MSG_DURATION = 3; // 3 秒
export interface IDvaInstance extends DvaInstance {
  _store?: any;
}

export interface versionInfoProps {
  VERSION: string;
  ENV: 'DEV' | 'TEST' | 'PRE' | 'PROD';
  INIT: boolean;
}

declare global {
  interface Window {
    __app?: IDvaInstance;
  }
}

// 1. Initialize
const app: IDvaInstance = dva({
  ...createLoading({
    effects: true,
  }),
  history: createBrowserHistory(),
  onError(error) {
    message.error(error.message);
  },
});

// 2. Model 迁移到路由中
// app.model(require('./models/example'));
// Model统一汇总到 /model/index,此处统一注入
Object.values(ModelSet).forEach((model) => {
  app.model(model as any);
});
// 3. Router
app.router(require('./router').default);

// 4. Start
app.start('#root');

export default app._store;

window.__app = app;
