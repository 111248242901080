import React, { ReactNode } from 'react';
import { connect } from 'dva';
import { Layout } from 'antd';
import { GlobalState, IRouterState } from '@/models/types';
import styles from './app.less';

const {
  Content,
} = Layout;

interface IAppProps {
  children: ReactNode;
  router: IRouterState;
}

const App: React.FC<IAppProps> = ({ children, router }) => {

  return (
    <div>
      <Layout className={styles.layout}>
        <Content className={styles.content} id="content">
          {children}
        </Content>
      </Layout>
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    router: state.router
  };
};

export default connect(mapStateToProps)(App);
