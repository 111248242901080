import request from '@/utils/request';
import { normalizeResult } from '@/utils/tool';

// 人机验证
export async function detection(options: any) {
  const res = await request(`/web/uic/api/v2/security/robotDetect`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: options,
  });
  return normalizeResult<any>(res);
}

// 重置密码发送认证信息接口 ticket 人机验证过后的token
export async function sendCode(options: any) {
  const res = await request(`/web/uic/api/v2/sms/resetPwdCode`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: options,
  });
  return normalizeResult<any>(res);
}

// 重置密码
export async function resetPassword(options: any) {
  const res = await request(`/web/uic/api/v2/mod/newpw`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: options,
  });
  return normalizeResult<any>(res);
}
